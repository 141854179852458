<template>
  <div class="download-container">
    <div class="notice-top" style="position: relative">
      <input class="input" placeholder="搜索关键字" v-model="waitSearch" />
      <img
        src="../../assets/images/xiaochazi.png"
        alt=""
        style="width: 18px; left: 300px; top: 10px; position: absolute"
        @click="clearInput"
        v-show="waitSearch !== ''"
      />
      <button class="btn" @click="waitSelectBtn" style="vertical-align: top">
        查询
      </button>
    </div>
    <div v-if="downloadList !== null && downloadList.length>0">
      <div class="notice-con">
        <ul>
          <li v-for="item in downloadList" :key="item.id">
            <div class="left-text">
              {{ item.fileName }}
            </div>
            <div>
              <span style="padding-right: 30px">上传时间：{{item.uploadTime}}</span>
              <button
                      class="btn"
                      style="width: 70px; height: 40px; cursor: pointer; color: #ffff"
                      @click="downloadFile2(item.fileUrl)"
              >
                <!--                                         <a class="btn" :href="item.fileUrl" style="color:#ffff;" download>下载</a>-->
                <!--                            <a class="btn" style="color:#ffff;" @click="downloadFile2(item.fileUrl)">下载</a>-->
                下载
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="notice-botom">
        <Pagination :total="total" :page="pageNo" key="download" name="download" v-show="downloadList !== null && downloadList.length > 0" />
      </div>
    </div>
    <div v-else class="no-date">
      暂无相关内容
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount,nextTick } from "vue";
import { fly, showAllUrl } from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import mitt from "@/utils/mitt";

export default {
  components: {
    Pagination,
  },
  setup() {
    const pageSize = ref(10);
    const pageNo = ref(1);
    const total = ref(10000000000);
    const noticeSearch = ref("");
    const downloadList = ref([]);
    const waitSearch = ref("");
    onMounted(() => {
      selectBtn();
      const hash = location.hash.replace(/#\/?/, "");
      console.log(hash);
      mitt.on(hash, pageBtn);

    });
     const pageBtn = (page) => {
        console.log(1);
        pageNo.value = page;
        selectBtn();
      };
    onBeforeUnmount(() => {
      mitt.off("download",pageBtn);
      const div = document.getElementsByClassName("realimeInfo-bottom")[0];
      const height = div.clientHeight;
      div.style.height = height + "px";
      nextTick(() => {
        div.style.height = "auto";
      });
    });
    const selectBtn = () => {
      fly
        .post(showAllUrl, {
          regionCode:localStorage.getItem('regionCode'), //园区code 必填
          minFileSize: null, // 非必填 下拉框选择项的最小大小
          maxFileSize: null, // 非必填 下拉框选择项的最大大小
          fileName: noticeSearch.value,
          pageNum: pageNo.value, //分页，显示第1页的数据,这个数据也可以省略不写，默认是1
          pageSize: pageSize.value, //不写就行，有需求的话可以设置一页有几个数据，这里默认10个
        })
        .then((res) => {
          downloadList.value = res.data.data.data.list;
          total.value = res.data.data.data.total;
          console.log(res);
        });
    };
    const clearInput = function () {
      waitSearch.value = "";
      noticeSearch.value = "";
    };
    const waitSelectBtn = () => {
      noticeSearch.value = waitSearch.value;
      selectBtn();
    };
    const downloadFile = function (url) {
      let appU = url.split("/");
      let fileName = appU[appU.length - 1].split(".")[0];
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    };
    const downloadFile2 = function (url) {
      url = url.replace(/\\/g, "/");
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      // 文件下载进度
      xhr.onprogress = (res) => {
        this.loadingTip =
          "源文件下载中: " + ((res.loaded / res.total) * 100).toFixed(2) + "%";
      };
      xhr.onload = () => {
        this.loadingTip = "";
        this.loading = false;

        if (xhr.status === 200) {
          // 获取文件blob数据并保存
          var num = url.lastIndexOf("/") + 1;
          //把参数和文件名分割开
          var fileName = url.substring(num).split("?")[0];
          var export_blob = new Blob([xhr.response]);
          var save_link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
          );
          save_link.href = URL.createObjectURL(export_blob);
          save_link.download = fileName;
          save_link.click();
        }
      };
      this.loading = true;
      xhr.send();
    };
    return {
      noticeSearch,
      selectBtn,
      total,
      pageSize,
      downloadList,
      waitSearch,
      clearInput,
      waitSelectBtn,
      downloadFile,
      pageNo,
      downloadFile2,
    };
  },
};
</script>

<style scoped>
.left-text {
  cursor: default !important;
}
.notice-con {
  max-width: 1230px;
}
.notice-con ul {
  min-height: 300px;
}
.notice-con ul li {
  display: flex;
  justify-content: space-between;
  color: #555;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  margin-top: 12px;
}
.notice-con ul li div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
  cursor: pointer;
}
.left-text {
  max-width: 800px;
}
li::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: linear-gradient(180deg, #999999 0%, #000000 100%);
  border-radius: 50%;
  position: absolute;
  left: -0px;
  top: 14px;
}
</style>
